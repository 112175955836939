import { Component, inject } from '@angular/core';
import { LoaderService } from 'src/app/shared/components/globals/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  protected loader: LoaderService = inject(LoaderService);

}
